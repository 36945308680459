import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

import { Icon, StaticMenuList, StaticMenuListStyles, View } from '@adac/core-view';

import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';
import StoresContext from '../../stores';

const NavBarStyle = styled(View)`
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  ${StaticMenuListStyles} {
    flex: 0 0 auto;
  }
`;

export default observer(() => {
  const { ui: uiStore } = React.useContext(StoresContext);

  const isInsurance = useIsInsuranceProduct();

  if (isInsurance) return null;

  const menulinks = [
    {
      id: '',
      route: `tel:+498976765577`,
      title: '', // DLC Zentrale
      image: isInsurance ? undefined : <Icon name='phone' round invert color='white' />,
    },
  ];

  return (
    <NavBarStyle padding>
      <StaticMenuList items={menulinks} uiStore={uiStore} />
    </NavBarStyle>
  );
});
