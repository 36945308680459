const { REACT_APP_API_BASE_URL: apiRootConfig, REACT_APP_SOCKET_BASE_URL: socketRoot, REACT_APP_CUSTOMER_PORTAL_URL: customerPortalConfig, } = process.env;
export const getAdacLinks = () => Object.freeze({
    dataprivacy: 'https://www.adac.de/datenschutz-dsgvo/online/',
    impressum: 'https://www.adac.de/impressum-ev/',
    cookie: 'https://www.adac.de/datenschutz-dsgvo/cookie-einstellungen/',
});
export const getCustomerRoutes = (validateConfig = process.env.NODE_ENV !== 'test') => {
    if (validateConfig && !customerPortalConfig)
        throw new Error('REACT_APP_CUSTOMER_PORTAL_URL has to be defined!');
    const customerPortal = customerPortalConfig || '';
    return Object.freeze({
        staticAsset: {
            pdfRoot: `${customerPortal}/assets/pdf`,
        },
    });
};
export const getApiRoutes = (validateConfig = process.env.NODE_ENV !== 'test', apiRoot = apiRootConfig || '') => {
    if (validateConfig && !apiRoot)
        throw new Error(`apiRoot has to be defined (apiRoot=${apiRoot}, REACT_APP_API_BASE_URL=${apiRootConfig})!`);
    const pdfPaths = Object.freeze({
        commissionReport: (action) => `/comission/opening/${action || ':action'}`,
        commissionExtraDamage: (action) => `/comission/breakdoor/${action || ':action'}`,
        finalStatusReport: (action, finalReportType) => `/final/report/${action || ':action'}${finalReportType ? `/${finalReportType}` : ''}`,
        comissionAdditional: () => '/',
    });
    return Object.freeze({
        paths: {
            pdf: pdfPaths,
            driver: {
                offerDispatch: (action) => `/offer/${action || ':action'}`,
                timesuggestionSaved: '/timesuggestion/saved',
                openingFailed: '/opening/failed',
            },
        },
        root: `${apiRoot}`,
        socket: `${socketRoot || apiRoot}`,
        healthCheck: '/api/health/check',
        zip: {
            zipCodes: `${apiRoot}/admin/zip-codes`,
            supportedCodes: `${apiRoot}/admin/zip-codes/supported-codes`,
            isSupported: (zip) => `${apiRoot}/admin/zip-codes/is-supported/${zip}`,
        },
        city: {
            cityNames: `${apiRoot}/admin/zip-codes/city-names`,
        },
        customer: {
            case: (token) => ({
                pdf: (type, action, finalReportType) => `${apiRoot}/${token}${pdfPaths[type](action, finalReportType)}`,
                status: `${apiRoot}/workflow/customer/status/${token}`,
                action: (action) => `${apiRoot}/workflow/customer/action/${token}/${action}`,
            }),
        },
        hubble: {
            post: `${apiRoot}/hubble/commissioning/homeassistance`,
        },
        admin: {
            settings: {
                notification: {
                    getSignInUserSetting: (userId) => `${apiRoot}/admin/settings/notification/${userId}`,
                    updateSignInUserSetting: (userId) => `${apiRoot}/admin/settings/notification/${userId}`,
                },
            },
        },
        backoffice: {
            escalation: {
                assistant: `${apiRoot}/admin/backoffice/assistant-escalation`,
                supervisor: `${apiRoot}/admin/backoffice/supervisor-escalation`,
                taken: `${apiRoot}/admin/backoffice/taken-cases`,
            },
            zipCode: (zip) => `${apiRoot}/admin/backoffice/zip-codes/${zip}`,
            holiday: {
                list: `${apiRoot}/admin/backoffice/holidays`,
                get: (id) => `${apiRoot}/admin/backoffice/holidays/${id}`,
            },
            region: {
                list: `${apiRoot}/admin/backoffice/region`,
                listActive: `${apiRoot}/admin/backoffice/region/listActive`,
            },
            users: {
                create: `${apiRoot}/admin/users/backoffice`,
                list: `${apiRoot}/admin/users/backoffice`,
                listBlocked: `${apiRoot}/admin/users/backoffice/blocked`,
                getMe: `${apiRoot}/admin/users/backoffice/me`,
                get: (userId) => `${apiRoot}/admin/users/backoffice/${userId}`,
            },
            adminLog: {
                getLatest: (entity, userId) => `${apiRoot}/admin/backoffice/adminlog/${entity}/${userId}`,
                getById: (id) => `${apiRoot}/admin/backoffice/adminlog/${id}`,
            },
            case: {
                setStatus: (token, action) => `${apiRoot}/admin/backoffice/case/${token}/${action}`,
                newCase: '/newcase',
                create: `${apiRoot}/admin/backoffice/commissioning`,
                basePrice: (zip, date) => `${apiRoot}/admin/cases/base-price/${zip}${date ? `/${date}` : ''}`,
                logs: (id) => `${apiRoot}/admin/backoffice/case/${id}/logs`,
                comment: (token) => `${apiRoot}/admin/backoffice/${token}/create-case-comment`,
                reportImages: (token) => `${apiRoot}/admin/backoffice/${token}/report-images`,
                updateReportImages: (token) => `${apiRoot}/admin/backoffice/${token}/report-images`,
                getCaseByToken: (token) => `${apiRoot}/admin/backoffice/${token}`,
                list: {
                    get: (id) => `${apiRoot}/admin/cases/${id}`,
                    closed: `${apiRoot}/admin/cases/closed`,
                    open: `${apiRoot}/admin/cases/open`,
                },
            },
        },
        statistics: {
            full: (params) => `${apiRoot}/admin/backoffice/fullreport${params ? `/${params.join('/')}` : ''}`,
        },
        auth: {
            authCode: (authCode) => `${apiRoot}/auth/${authCode}`,
            mfa: {
                passwordChangeStart: `${apiRoot}/auth/mfa/password-change/start`,
                passwordResetStart: `${apiRoot}/auth/mfa/password-reset/start`,
                confirm: `${apiRoot}/auth/mfa/confirm`,
            },
            loginToCompanyAdmin: `${apiRoot}/auth/login/company`,
            loginToBackoffice: `${apiRoot}/auth/login/backoffice`,
            users: {
                changePassword: `${apiRoot}/admin/users/change-password`,
                changePhone: `${apiRoot}/admin/users/change-phone`,
                resetPassword: `${apiRoot}/admin/users/reset-password`,
                getMe: `${apiRoot}/admin/users/me`,
                unblock: (userId) => `${apiRoot}/admin/users/unblock/${userId}`,
            },
        },
        driverAdmin: {
            list: `${apiRoot}/admin/snd-drivers`,
            create: `${apiRoot}/admin/snd-drivers`,
            get: (driverId) => `${apiRoot}/admin/snd-drivers/${driverId}`,
        },
        companyAdmin: {
            list: `${apiRoot}/admin/backoffice/snd-companies`,
            listActive: `${apiRoot}/admin/backoffice/snd-companies/active`,
            getById: (id) => `${apiRoot}/admin/backoffice/snd-companies/${id}`,
            getCaseByToken: (token) => `${apiRoot}/admin/snd-companies/case/${token}`,
            putById: (id) => `${apiRoot}/admin/backoffice/snd-companies/${id}`,
            editDriversList: (companyId) => encodeURI(`/admin/backoffice/snd-companies/${companyId}/admin/backoffice/drivers`),
            getNotificationSettings: (companyId) => `${apiRoot}/admin/backoffice/snd-companies/settings/notification/${companyId}`,
            saveNotificationSettings: () => `${apiRoot}/admin/backoffice/snd-companies/settings/notification`,
        },
        partner: {
            getMyCompany: `${apiRoot}/admin/snd-companies/me`,
            notificationSettings: `${apiRoot}/admin/settings/notification`,
            caseList: {
                closed: `${apiRoot}/admin/snd-companies/cases/closed`,
                open: `${apiRoot}/admin/snd-companies/cases/open`,
            },
            case: (token) => ({
                offerDispatch: `${apiRoot}/${token}`,
                status: (companyId) => `${apiRoot}/workflow/partner/status/${token}/${companyId}`,
                report: {
                    company: (companyId) => `${apiRoot}/workflow/partner/report/${token}/${companyId}`,
                    view: (reportType, finalReportType) => `${apiRoot}/workflow/partner/report/${token}/view/${reportType}${finalReportType ? `/${finalReportType}` : ''}`,
                },
                action: {
                    callCustomer: () => `${apiRoot}/workflow/partner/callCustomer/${token}`,
                    setStatus: (action, companyId) => `${apiRoot}/workflow/partner/action/${token}/${action}/${companyId}`,
                    position: {
                        get: `${apiRoot}/workflow/partner/position/${token}`,
                        getAll: `${apiRoot}/workflow/partner/position/${token}/all`,
                    },
                    timeSuggestion: {
                        reject: `${apiRoot}/workflow/partner/reject-job/${token}`,
                        accept: `${apiRoot}/workflow/partner/accept-job/${token}`,
                    },
                    getDriverList: `${apiRoot}/workflow/partner/accept-job/${token}/drivers`,
                    invoice: {
                        upload: `${apiRoot}/admin/documents/${token}`,
                        bypass: `${apiRoot}/admin/documents/bypass/${token}`,
                    },
                },
            }),
        },
        location: {
            retrieve: {
                code: `${apiRoot}/admin/utilities/geolocation/retrieve-code`,
                address: `${apiRoot}/admin/utilities/geolocation/retrieve-door-address`,
                billing: `${apiRoot}/admin/utilities/geolocation/retrieve-billing-address`,
            },
        },
    });
};
