import { getApiRoutes } from '../networking/routes.js';
const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
const applySaltToChar = (salt) => (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
export const cipher = (salt) => {
    const byteHex = (n) => `0${Number(n).toString(16)}`.substr(-2);
    return (text) => textToChars(text).map(applySaltToChar(salt)).map(byteHex).join('');
};
export const decipher = (salt) => (encoded) => (encoded.match(/.{1,2}/g) || [''])
    .map((hex) => parseInt(hex, 16))
    .map(applySaltToChar(salt))
    .map((charCode) => String.fromCharCode(charCode))
    .join('');
export const cipherLink = (companyId, token) => {
    const cipherFunc = cipher(process.env.COMPANY_ID_CIPHER || '07b03a43fce2bbb506ae8ce970e3bbf2');
    return `+${cipherFunc(`${String(companyId)} ${token}`)}`;
};
export const cipherSndOfferLink = (cipheredToken) => getApiRoutes(undefined, process.env.REACT_APP_SND_DRIVER_PORTAL_URL).partner.case(cipheredToken).offerDispatch;
export const isCiphered = (text) => /\+[0-9a-f]+/.test(text);
